@import "bootstrap/dist/css/bootstrap.min.css";

.App {
  text-align: center;
}

html {
  min-width: 570px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.vh-50 {
  height: 53vh;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: var(
    --grad,
    linear-gradient(135deg, #53972c 0%, #acdd59 100%)
  ) !important;
}

/* Generic check box styles */

.form-check-input[type="checkbox"] {
  border: 2px solid #8ec449 !important;
  width: 20px !important;
  height: 20px !important;
}
.form-check-input[type="checkbox"]:checked {
  background-color: #8ec449 !important;
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 7px !important;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #cacecd;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

.main-container {
  max-width: 3200px;
  height: 100%;
  padding: 2rem 6rem 2rem 6rem;
  background: none !important;
}

.chat-history-box {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.history-buttons:hover {
  /* background-color: #a6aba9; */
  background: linear-gradient(to left, #a6aba9, #ffffff);
}

/* Stepper styles */

.custom-step-root {
  background-color: transparent;
}

.custom-step-icon {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  padding: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #969c9a !important;
  border: 1px solid #969c9a;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.custom-step-icon.active {
  background-color: #faba31 !important;
  width: 63px;
  height: 63px;
  border-color: transparent;
  color: #fff !important;
}

.main-active {
  border: 3px solid #faba31 !important;
  width: 75px;
  height: 75px;
  padding: 3.8px;
  border-radius: 50%;
}

.custom-step-icon.active img {
  filter: brightness(0) invert(1) !important;
}

.MuiStepLabel-iconContainer.custom-step-completed .custom-border::before {
  content: "\2713";
  font-size: 26px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 14px;
  right: 37%;
  color: white;
}

.custom-step-completed .custom-border {
  background: green;
  border-radius: 50%;
  position: relative;
}

.custom-step-completed span img {
  display: none !important;
}

.subTitle {
  font-size: 16px !important;
  color: #636967 !important;
  font-weight: 400 !important;
  text-align: center;
}

.title {
  font-size: 20px !important;
  color: #191a1a !important;
  text-align: center;
}

.css-zpcwqm-MuiStepConnector-root,
.css-15oeqyl {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 35px !important;
  left: calc(-39% + 20px) !important;
  right: calc(61% + 20px) !important;
}

.autoplay-btn {
  width: 200px;
  height: 200px;
  border: 5px solid transparent;
  border-radius: 50%;
  /* border-image: linear-gradient(to right, #53972C, #ACDD59) 1; */
  border: 5px solid #53972c;
  color: #53972c;
  font-size: 40px;
  font-weight: 500;
  background-color: transparent;
}

.content-heading {
  color: #53972c !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

.content-stats {
  color: #2e3130 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.content-icon img {
  filter: invert(45%) sepia(76%) saturate(378%) hue-rotate(54deg)
    brightness(97%) contrast(97%) !important;
}

.input-field {

  outline: unset !important;
  padding: 1rem 2rem;
  display: flex;
  align-items: baseline;
  overflow: hidden;
}
.upload-img {
  background: white;
  /* justify-content: center; */
  border-radius: 0px 0px 1.375rem 1.375rem;
}
textarea {
  outline: unset !important;
  padding: 1rem 1rem;
}

.scroll-hide {
  width: 100%;
  /* height: 100%; */
  max-height: 300px !important;
  overflow: auto;
  overflow-x: hidden;
}

.scroll-hide-content {
  padding-top: 20px;
  /* padding-left: 4.25rem;
  padding-right: 4.25rem; */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: -5px;
  resize: none;
}

.stepper-container {
  display: flex !important;
  justify-content: space-around !important;
  flex-wrap: wrap !important;
  padding: 20px;
}

@media (max-width: 1500px) {
  .css-z7uhs0-MuiStepConnector-line {
    display: none !important;
  }
}

@media (max-width: 992x) {
  .main-container {
    padding: 2rem !important;
  }
}

@media (max-width: 769px) {
  .main-container {
    padding: 2rem;
  }
}

@media (max-width: 600px) {
  .main-container {
    padding: 2rem 1rem 2rem 1rem;
  }

  .stepper-container {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 10px;
  }
}

.modal-body-content {
  margin-top: -4px;
}

/* .video-container {
  position: relative;
  transform: scale(1.25);
  transform-origin: center;
  overflow: hidden;
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  background: #fff;
} */

.modal-body-content {
  margin-top: -4px;
}

.bg-video {
  height: 100%;
  width: 100%;
  position: fixed;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
}

.bottom-arrow:before {
  content: "";
  border-top: 20px solid #cc0000;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
.chat-symptoms-img {
  max-width: 350px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}
.symptoms-img {
  position: relative;
  max-width: 120px;
  margin-left: 5rem;
   display: flex;
  justify-content: center;
}
.symptoms-img:hover {
  opacity: 1;
}
.remove-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  background: red;
  border-radius: 50%;
  color: white;
  z-index: 999;
}
.eye-icon-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ant-color-text-light-solid);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: white;
  transition: opacity var(--ant-motion-duration-slow);
}
